<template>
  <TopNavBar />

  <!-- breadcrumb area start -->
  <div
    class="breadcrumb-area"
    style="background-image: url(assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">Our Concept</h1>
            <ul class="page-list">
              <li>
                <router-link class="nav-link" :to="{ name: 'home' }"
                  >Home</router-link
                >
              </li>
              <li>Our Concept</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb area End -->

  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="img-with-video">
            <div class="img-wrap">
              <img src="assets/img/we-provide/1.png" alt="video" />
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title">Knowledge is <span>power</span></h2>
              <p>
                Staying competitive in the aviation business requires up-to-date
                knowledge of airlines' moves. This is no small feat due to
                constant network changes and price fluctuations.
              </p>

              <p>
                For airlines, empty seats or wrong route choices mean missed
                revenue. For airports, capacity and route support should be
                allocated to the most appropriate carriers.
              </p>

              <p>
                Knowledge of the marketplace and its changes, allows for swift
                action to meet your objectives.
              </p>
            </div>
            <div class="text-center">
              <a
                class="btn btn-radius btn-blue s-animate-3 mt-3"
                href="mailto:info@fareplay.aero"
                ><i class="fa fa-paper-plane"></i> Contact us</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <!-- sbst service area start -->
  <div class="sbst-service-area pd-top-80">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-10">
          <div class="section-title">
            <h2 class="title">
              The <span>right</span> information at your fingertips
            </h2>
            <p>
              FarePlay equips you with raw pricing data for most airlines on
              virtually any route worldwide — in a cost-effective way.
            </p>

            <p>
              In addition, we offer a suite of reports that let you distill the
              insights that you are looking for. Here are some typical questions
              we can help you answer:
            </p>
          </div>
        </div>
      </div>
      <div class="row custom-gutters-20 justify-content-center">
        <div class="col-lg-4 col-sm-6">
          <div class="single-service-2 text-center">
            <div class="thumb">
              <img src="assets/img/startup/service/1.svg" alt="service" />
            </div>
            <h6>For airlines</h6>
            <ul>
              <li class="text-left py-2">
                What are the demand hotspots from each of our bases?
              </li>
              <li class="text-left py-2">
                Do other airlines/geographies observe similar demand strength /
                weakness?
              </li>
              <li class="text-left py-2">
                What is the fare environment for potential new markets?
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="single-service-2 text-center">
            <div class="thumb">
              <img src="assets/img/startup/service/2.svg" alt="service" />
            </div>
            <h6>For airports</h6>
            <ul>
              <li class="text-left py-2">
                How do routes in my network perform for my airlines?
              </li>
              <li class="text-left py-2">
                How do route fares compare to those of similar airports?
              </li>
              <li class="text-left py-2">
                What routes struggle and should be promoted?
              </li>
              <li class="text-left py-2">
                Which airlines are best to start new routes?
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="single-service-2 text-center">
            <div class="thumb">
              <img src="assets/img/startup/service/3.svg" alt="service" />
            </div>
            <h6>For investors</h6>
            <ul>
              <li class="text-left py-2">
                Which airlines and airports command a premium over others? How
                much?
              </li>
              <li class="text-left py-2">
                Which airlines typically sell out too early?
              </li>
              <li class="text-left py-2">
                Which airlines and airports consistently seize the most
                profitable opportunities?
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- sbst service area end -->

  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="img-with-video">
            <div class="img-wrap">
              <img src="assets/img/we-provide/4.png" alt="video" />
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title">
                <span>Business-focussed</span> without unnecessary bells and
                whistles
              </h2>
              <p>
                Our organisation and products have been designed with a lean
                mindset, combining the skills of experts in their respective
                areas. This way, you don't pay for unnecessary overhead and you
                need not waste time talking to people who don't understand your
                particular requirements.
              </p>

              <p>
                We have a track record of successfully transforming data into
                insights in a variety of contexts, so feel free to reach out.
              </p>
            </div>
            <div class="text-center">
              <a
                class="btn btn-radius btn-blue s-animate-3 mt-3"
                href="mailto:info@fareplay.aero"
                ><i class="fa fa-paper-plane"></i> Contact us</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <LetsTalk />
  <Footer />

  <!-- back to top area start -->
  <div class="back-to-top">
    <span class="back-top"><i class="fa fa-angle-up"></i></span>
  </div>
  <!-- back to top area end -->
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "OurConcept",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
};
</script>
